import React from 'react';
import '../Dropdowns/Dropdown.css';

const Dropdown = ({ label, items }) => {
  // Function to generate dropdown items using map()
  const renderDropdownItems = () => {
    return items.map((item, index) => (
      <li key={index}>
        <a className="dropdown-item" href={item.url}>{item.label}</a>
      </li>
    ));
  };

  return (
    <li className="nav-item dropdown">
      <a className="nav-link dropdown-toggle" href="#" id={`dropdownMenuLink${label}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {label}
      </a>
      <ul className="dropdown-menu" aria-labelledby={`dropdownMenuLink${label}`}>
        {renderDropdownItems()}
      </ul>
    </li>
  );
};

export default Dropdown;
