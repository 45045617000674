import React from 'react'
import SvcCard from '../SvcCard/SvcCard'
import '../SvcPlatform/SvcPlatform.css'

function SvcPlatform() {
  return (
    <div>
        <div>
        <h3 className=" fz-40 heading"><b>India's fastet growing legal services platform</b></h3>
        </div>
        <div className='row'>
        <div className='svcPlatform' style={{}}>
        <SvcCard src="https://assets.vakilsearch.com/compressed_images/icon-cs.svg" title="Compliances" desc="Our chartered accountants &amp; company secretaries will keep your books in order."/>
            {/* <SvcCard src="https://assets.vakilsearch.com/compressed_images/icon-lawyers.svg" title="Legal" desc="Your documents, contracts, and registrations handled by expert lawyers."/> */}
            <SvcCard src="https://assets.vakilsearch.com/compressed_images/icon-ca.svg" title="Tax" desc="Filing returns and paying your taxes – made easier for you."/>
        </div>
        </div>
        </div>
  )
}

export default SvcPlatform