import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Homepage from './pages/Homepage/Homepage';
import Footer from "./components/Footer/Footer";
import ITR from './pages/ITR/ITR';
import RequestCallback from './components/Callback/Callback';
import ReactGA from 'react-ga';

const TRACKING_ID = "G-CZ5VJ8XM4Q";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (<Router>

    <Navbar />
    <Routes>
      <Route path="/" exact element={<Homepage />} />
      <Route path="/itr" exact element={<ITR/>}/>
      <Route path='/requestcallback' exact element={<RequestCallback/>}/>
    </Routes>
    <div className="Footer">
      <Footer />
    </div>
  </Router>);
}

export default App;
