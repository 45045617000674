import * as React from 'react';
import '../Card/Card.css'

export default function SvcCard({src,title,desc,hoverDesc,link}) {
  return (
<div className='card-cont'>
    <div className="svcCard">
        <div className="figure" >
            <img src={src} alt="" onAnimationStart={false} onTouchMoveCapture={false}/>
        </div>
        <div className="content">
            <h2 className="title">{title}</h2>
            <p className={"body"}>{desc}</p>
            <p className='hoverDesc'>{hoverDesc}</p>
        </div>
        <div className="footer">
            <a href={link} className="btn btn-primary btnSvc">Enquire Now</a>
        </div>
    </div>
    </div>
  );
}


