import React from 'react';
import Avatar from "../../assets/boy.png";
import "./Feedback.css";

function Feedback() {
  return (
    <>
    <div className="heading">
    <h3 className='mb-3 fz-40'><b>Trusted by our customers</b></h3>
  </div>
    <div className="outerdiv">
    <div className="innerdiv">
      <div className="div1 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img src={Avatar} alt=""/>
          </div>
          <div className="detbox">
            <p className="name">Aditi Jaiswal</p>
          </div>
        </div>
        <div className="review">
          <h4>Exceptional Professionalism and Attention to Detail</h4>
        <p>“I have had the pleasure of being a client of Compliance4u for several years now, and I can confidently say that their professionalism and attention to detail are unmatched. From the very first consultation, it was clear that they were not just another accounting firm but a partner deeply committed to ensuring our financial success. 
          One of the most impressive aspects of Compliance4u's service is their ability to navigate complex tax issues with ease. As our business grew, so did the complexities of our tax obligations. 
          However, Compliance4u has consistently provided expert guidance and strategic insights that have allowed us to navigate these challenges seamlessly. They have a deep understanding of current tax laws and regulations, ensuring that we remain compliant while also maximizing our tax efficiencies.”</p>
        </div>
      </div>
      <div className="div2 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img src={Avatar} alt=""/>
          </div>
          <div className="detbox">
            <p className="name">Aakriti Gupta</p>
          </div>
        </div>
        <div className="review">
          <h4>Clear, Insightful, and Trustworthy</h4>
        <p>"I've relied on Compliance4u's expertise for personal tax filing and financial planning, and they've consistently delivered excellent service. They take the time to explain everything clearly and ensure I understand all aspects of my financial situation."</p>
        </div>
      </div>
      <div className="div3 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img src={Avatar} alt=""/>
          </div>
          <div className="detbox">
            <p className="name dark">Utkarsh Singh</p>
          </div>
        </div>
        <div className="review dark">
          <h4>Impactful Industry-Specific Solutions</h4>
          <p>“ Choosing Compliance4u was one of the best decisions we made for our business. Their deep knowledge of industry-specific tax regulations and their proactive approach in identifying savings opportunities have had a significant impact on our bottom line. They are responsive, reliable, and genuinely care about their clients' success.
          From the outset, Compliance4u demonstrated a deep understanding of our industry's unique challenges and opportunities. Their knowledge of specialized tax laws and regulations has not only ensured compliance but has also allowed us to leverage various incentives and deductions specific to our sector. This strategic approach has effectively minimized our tax liabilities while maximizing our eligible savings, directly contributing to our profitability.”</p>
        </div>
      </div>
      <div className="div4 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img src={Avatar} alt=""/>
          </div>
          <div className="detbox">
            <p className="name dark">Anju Jain</p>
          </div>
        </div>
        <div className="review dark">
          <h4>Expert Guidance and Savings</h4>
        <p>“ Working with Compliance4u has been a game-changer for our business. Their team's expertise in tax planning and financial strategy has saved us significant amounts annually. They are proactive, thorough, and always accessible when we need advice. Highly recommend their services! ”</p>
        </div>
      </div>
      <div className="div5 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img src={Avatar} alt=""/>
          </div>
          <div className="detbox">
            <p className="name">Ajay Dhobale</p>
          </div>
        </div>
        <div className="review">
          <h4>Meticulous Audit and Strategic Recommendations</h4>
        <p>“ We engaged Compliance4u for an audit last year, and I must say they exceeded our expectations. Their team not only conducted a meticulous audit but also provided insightful recommendations to streamline our accounting processes. They are reliable, efficient, and a pleasure to work with.
        What truly impressed us were the insightful recommendations provided by [CA Firm Name]. Beyond identifying compliance issues or discrepancies, they offered strategic suggestions to streamline our accounting processes. Their recommendations were practical and tailored to our specific operational needs, aimed at improving efficiency and enhancing financial reporting accuracy. Implementing these suggestions has not only simplified our internal processes but has also strengthened our overall financial management practices. ”</p>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default Feedback