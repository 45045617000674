import * as React from 'react';
import "./SvcCard.css"

export default function SvcCard({src,title,desc}) {
  return (
    <>
    {/* <div className='row'> */}
    <div className="col-md-4">
    <div className="vs-media-group media">
    <div className="vs-media-img">
    <img width="32" height="36" className="align-self-top mr-3" src={src} alt="Legal"/>
    </div>
    <div className="media-body">
      <p className='media-para'>
    <b>{title}</b></p><p>{desc}</p></div></div>
 
   </div>
   {/* </div> */}
    </>
  );
}
