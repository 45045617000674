import React from "react";
import PlanCard from "../../components/PlanCard/PlanCard";
import "../ITR/ITR.css";

function ITR() {
  return (
    <div>
      <div className='popular-cont' >
        <h2 className='popular'>Popular ITR Plans :</h2>
    <div className='cate-cont '>
    <a href='#individuals' className='cate-link'><h3 className='catetext'>For Individuals</h3></a>
    <a href='#professionals' className='cate-link'><h3 className='catetext'>For Professionals</h3></a>
    </div>
    </div>
      <div className="row">
        
      <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="plan-cont" id="individuals">
            <PlanCard
              src="https://assets1.cleartax-cdn.com/cleartax/images/1630533080_group18140690.png"
              title="Salary or House Rent Income"
              price="999"
              items={[
                "Salary",
                "House property",
                "Withdrawal from PF and bank deposits",
                "Donations",
              ]}
              link="/requestcallback"
            />
          </div>
        </div>

        {/* For screens 768px and above, show 2 cards per row */}
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="plan-cont" id="individuals">
            <PlanCard
              src="https://assets1.cleartax-cdn.com/cleartax/images/1629879436_group2823.png"
              title="Capital Gains Income"
              price="1499"
              items={[
                "Everything in Salary/House Rent Plan",
                "Capital Gains from sale of stocks, mutual funds & property",
                "Gains from Lottery, Gaming or Awards",
              ]}
              link="/requestcallback"
            />
          </div>
        </div>

        {/* For screens 1024px and above, show 3 cards per row */}
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="plan-cont" id="individuals">
            <PlanCard
              src="https://assets1.cleartax-cdn.com/cleartax/images/1629879436_group2823.png"
              title="For Professionals and Freelancers"
              price="2499"
              items={[
                "Income for professionals (like Freelancers, Doctors) & small businesses",
                "Applicable Annual Turnover < Rs. 2 cr for businesses or Gross Receipt < Rs. 50 Lacs for Professionals",
                "Also covers salary, other incomes and capital gains",
              ]}
              link="/requestcallback"
            />
          </div>
        </div>

        {/* For screens 1024px and above, show 3 cards per row */}
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="plan-cont" id="professionals">
            <PlanCard
              src="https://assets1.cleartax-cdn.com/cleartax/images/1630533061_group3791.png"
              title="Foreign Income"
              price="4999"
              items={[
                "Everything in Capital Gains Income Plan",
                "Income earned outside India",
                "Income earned in India for NRE, NRO A/c",
                "DTAA guidance",
              ]}
              link="/requestcallback"
            />
          </div>
        </div>

        {/* For screens 1024px and above, show 3 cards per row */}
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="plan-cont" id="professionals">
            <PlanCard
              src="https://assets1.cleartax-cdn.com/cleartax/images/1630533080_group18140690.png"
              title="For Traders"
              price="4999"
              items={[
                "For Traders",
                "Preparation of Account Summary, P&L and balance sheet",
                "Also covers salary, other incomes and capital gains",
                "Audit fee and DSC not included",
              ]}
              link="/requestcallback"
            />
          </div>
        </div>

        {/* For screens 1024px and above, show 3 cards per row */}
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="plan-cont" id="professionals">
            <PlanCard
              src="https://assets1.cleartax-cdn.com/cleartax/images/1630533061_group3791.png"
              title="For Business"
              price="5999"
              items={[
                "Business income from business having upto 200 transactions",
                "Preparation of Account Summary, P&L and balance sheet",
                "Also covers salary, other incomes and capital gains",
                "Audit Fee and DSC not included",
              ]}
              link="/requestcallback"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ITR;
