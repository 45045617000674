import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Callback from "../../assets/Callback.jpg";
import TextField from "@mui/material/TextField";
import { MenuItem, Select, InputLabel,FormControl } from "@mui/material";
import "../Callback/Callback.css";

function RequestCallback() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const [service, setService] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "Name is required.";
    tempErrors.phone = phone ? "" : "Phone number is required.";
    tempErrors.email = email ? "" : "Email is required.";

    const phoneRegex = /^[0-9]{10}$/;
    if (phone && !phoneRegex.test(phone)) {
      tempErrors.phone = "Phone number is invalid.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      tempErrors.email = "Email is invalid.";
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      Name: name,
      PhoneNumber: phone,
      Email: email,
      TimeSlot: timeSlot,
      ServiceDescription: service,
    };

    if (validate()) {
      axios
        .post(
          "https://sheet.best/api/sheets/c179f9be-acc2-414c-b609-e38bf310820e",
          data
        )
        .then((response) => {
          console.log(response);
          setName("");
          setPhone("");
          setEmail("");
          setTimeSlot("");
          setService("");

          Swal.fire({
            title: "Thank you!",
            text: "Your request has been submitted successfully!",
            icon: "success",
          });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          Swal.fire({
            title: "Oops...",
            text: "Something went wrong! Please try again later.",
            icon: "error",
            customClass: {
              container: "mobile-swal-container", // Custom class for mobile responsiveness
            },
          });
        });
    } else {
      Swal.fire({
        title: "Form Errors",
        text: "One or more values are incorrect. Please check and try again.",
        icon: "error",
        customClass: {
          container: "mobile-swal-container", // Custom class for mobile responsiveness
        },
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="callback-cont">
          <div>
            <img className="callback-img" src={Callback} alt="callback" />
          </div>
          <div className="callback-form-cont">
            <form onSubmit={handleSubmit}>
              <h4
                style={{
                  textAlign: "center",
                  color: "#000000a6",
                }}
              >
                Welcome to Compliance4u!
              </h4>
              <h2 className="callback-txt">Register your request </h2>

              <div className="mb-3">
                <TextField
                  required
                  label="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  error={!!errors.name}
                  helperText={errors.name}
                  fullWidth
                />
              </div>

              <div className="mb-3">
                <TextField
                  required
                  label="Enter phone number"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  fullWidth
                />
              </div>
              <div className="mb-3">
                <TextField
                  required
                  label="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                />
              </div>
              <div className="mb-3">
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Time Slot</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={timeSlot}
                  label="Time Slot"
                  onChange={(e) => setTimeSlot(e.target.value)}
                >
                  <MenuItem value="10 AM to 12 PM">10 AM to 12 PM</MenuItem>
                  <MenuItem value="12 PM to 2 PM">12 PM to 2 PM</MenuItem>
                  <MenuItem value="2 PM to 4 PM">2 PM to 4 PM</MenuItem>
                  <MenuItem value="4 PM to 6 PM">4 PM to 6 PM</MenuItem>
                </Select>
                </FormControl>
              </div>
              <div className="mb-3">
                <TextField
                  label="Service Description"
                  onChange={(e) => setService(e.target.value)}
                  value={service}
                  fullWidth
                />
              </div>

              <div className="d-grid" fullWidth>
                <button type="submit" className="btn btn-primary btnSubmit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestCallback;
