import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/FullLogo.png';
import Dropdown from '../Dropdowns/Dropdown';
import './navbar.css';

const dropdownMenus = [
  {
    id: 1,
    label: 'Talk to Expert',
    items: [
      { label: 'Consult with CA', url: '/requestcallback' },
      { label: 'Consult with CS', url: '/requestcallback' },
      { label: 'Consult with Lawyer', url: '/requestcallback' }
    ]
  },
  {
    id: 2,
    label: 'Start a Business List',
    items: [
      { label: 'Register proprietorship Firm', url: '/requestcallback' },
      { label: 'Register partnership Firm', url: '/requestcallback' },
      { label: 'Register Limited Liability Partnership (LLP)', url: '/requestcallback' },
      { label: 'Register one person company (OPC)', url: '/requestcallback' },
      { label: 'Register Private Limited company (OPC)', url: '/requestcallback' },
      { label: 'Register Section 8 Company', url: '/requestcallback' },
      { label: 'Register Trust', url: '/requestcallback' },
      { label: 'Register Society', url: '/requestcallback' }

    ]
  },
  {
    id: 3,
    label: 'Registration & Licences',
    items: [
      { label: 'GST Registration', url: '/requestcallback' },
      { label: 'Import Export Licence', url: '/requestcallback' },
      { label: 'Trademark Registration Services', url: '/requestcallback' },
      { label: 'MSME Registration', url: '/requestcallback' },
      { label: 'Startup India Registration', url: '/requestcallback' },
      { label: 'ISO Certification', url: '/requestcallback' },
      { label: 'Legal Metrology Registration', url: '/requestcallback' },
      { label: 'Hallmark Registration', url: '/requestcallback' },
      { label: 'BIS Registration', url: '/requestcallback' },
      { label: 'ESI Registration', url: '/requestcallback' },
      { label: 'Provident Fund (PF) Registration ', url: '/requestcallback' },
      { label: 'Professional Tax (PT) Registration ', url: '/requestcallback' },
      { label: 'Shop and Establishment Registration', url: '/requestcallback' },
      { label: '80G / 12A Registration', url: '/requestcallback' }
    ]
  },
  {
    id: 4,
    label: 'Business compliances',
    items: [
      { label: 'GST Compliances & Advisory', url: '/requestcallback' },
      { label: 'Company compliances', url: '/requestcallback' },
      { label: 'Trademark Services', url: '/requestcallback' },
      { label: 'RoDTEP', url: '/requestcallback' },
      { label: 'ESI compliances', url: '/requestcallback' },
      { label: 'PF compliances', url: '/requestcallback' },
      { label: 'Accounting Services', url: '/requestcallback' },
      { label: 'TDS Return fillings', url: '/requestcallback' },
      { label: 'ITR Filling services', url: '/requestcallback' },
      { label: 'Income Tax Notice Reply', url: '/requestcallback' }
    ]
  },
  {
    id: 5,
    label: 'Others',
    items: [
      { label: 'Close Partnership Firm', url: '/requestcallback' },
      { label: 'Close LLP', url: '/requestcallback' },
      { label: 'Close Company', url: '/requestcallback' },
      { label: 'Convert Proprietorship to company', url: '/requestcallback' },
      { label: 'Convert partnership to LLP', url: '/requestcallback' },
      { label: 'Convert Private to public company', url: '/requestcallback' },
      { label: 'Convert Private to one person company', url: '/requestcallback' },
      { label: 'Digital Signature Certificate', url: '/requestcallback' }
    ]
  }
];


function Navbar() {
  return (
    <div className="nav-cont position-sticky top-0 shadow-lg " style={{ height: '60px' }}>
        <div className="navbar navbar-expand-lg nav">
          <div className="container-fluid nav-cont">
            <Link to="/">
              <div className="navbar-brand" href="/">
                <img src={Logo} alt="logo" className="logo" style={{ maxHeight: '50px' }}/>
              </div>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse nav-cont" id="navbarNav">
              <div className="container-fluid">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav-items">
            {dropdownMenus.map((menu) => (
              <Dropdown key={menu.id} label={menu.label} items={menu.items} />
            ))}
          </ul>
        </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Navbar