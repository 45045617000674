import React from 'react'
import Reasons from '../Reasons/Reasons'
import sign from "../../assets/sign.png"
import svc from "../../assets/customer.png";
import peace from '../../assets/relax.png';
import userFriendly from "../../assets/search.png";
import client from "../../assets/target.png";
import guarantee from "../../assets/guaranteed.png";
import './Features.css'

function Features() {
  return (
    <div>
    <div class="mb-5">
    <h3 class=" mb-3 fz-40 heading"><b>Reasons to choose us</b></h3>
    </div>
    <div className='row'>
    <div className='featurecard'>
        <Reasons src={sign} title="Best prices and no surprises" />
        <Reasons src={svc} title="All services at single place"/>
    </div>
    </div>
    {/* <div className='row'> */}
    <div className='featurecard'>
        <Reasons src={peace} title="We provide peace of mind" />
        <Reasons src={userFriendly} title="User friendly dashboard"/>
    </div>
    <div className='featurecard'>
        <Reasons src={client} title="Client focused solutions" />
        <Reasons src={guarantee} title="We guarantee our work"/>
    </div>
    </div>
  )
}

export default Features