import React from 'react';
import './Services.css'
import Card from '../Card/Card';

function Services() {
  return (
    <>
    <div className="svcCont">
        <h5 className='heading'>Popular Services For You</h5>
        <div className='cardCont'>
        <Card src="https://assets1.cleartax-cdn.com/finfo/wg-utils/retool/1c380469-b051-4882-a1dc-d71fe81fed7a.png" title="GST Services" desc="File your taxes at ease with our tax expert if you need" hoverDesc="GST Services &#8377;499/- onwards" link="/requestcallback"/>
        <Card src="https://assets1.cleartax-cdn.com/cleartax/images/1605873415_taxreturnfilled.svg" title="ITR Services" desc="File your taxes at ease with our tax expert if you need" hoverDesc="ITR Services &#8377;999/- onwards" link="/itr"/>
        <Card src="https://assets1.cleartax-cdn.com/cleartax/images/1651557178_group17737.svg" title="Start a Business" desc="File your taxes at ease with our tax expert if you need" hoverDesc="New Business Registration &#8377;999/- onwards" link="/requestcallback"/>
        </div>
        <div className='cardCont'>
        <Card src="https://assets.clear.in/cleartax/images/1689834930_group1814218517.png" title="ROC Services" desc="File your taxes at ease with our tax expert if you need" hoverDesc="ROC Services &#8377;999/- onwards" link="/requestcallback"/>
        <Card src="https://assets1.cleartax-cdn.com/cleartax/images/1651559329_incometaxreturns.svg" title="Food License Services" desc="File your taxes at ease with our tax expert if you need" hoverDesc="Food License Services &#8377;999/- onwards" link="/requestcallback"/>
        <Card src="https://assets1.cleartax-cdn.com/cleartax/images/1651559337_group18140924.svg" title="Trademark Services" desc="File your taxes at ease with our tax expert if you need" hoverDesc="Trademark Services &#8377;999/- onwards" link="/requestcallback"/>
        </div>
    </div>
    </>
  )
}

export default Services