import React from "react";
import "../PlanCard/PlanCard.css";

function PlanCard({title, price, src,items=[],link}) {
  return (
    <div className='plan-outer-cont'>
    <div className="card planCard" >
      <div>
    <img className="card-img-top plan-img" src={src} alt="plan"/>
    <div className="card-body">
      <h4 className="card-title" style={{fontSize:'20px',textAlign: "center"}}>{title}</h4>
      <p className="card-text fw-bold fs-2 text-center" style={{color: "#314259"}}>&#8377; {price} /-</p>
    </div>
    </div>
    <p className='text-left' style={{color: "#314259"}}>Covers income from</p>
    <ul className="list-group list-group-flush" >
    {items.map((item, index) => (
          <li key={index} className="list-group-item " style={{color: "#314259"}} >{item}</li>
        ))}
    </ul>
    <div className="card-footer">
    <a href={link} className="btn btn-primary btnPlan" type="submit">Enquire Now</a>
    {/* <a href="#" class="card-link">Know more</a> */}
    </div>
  </div>
  </div>
  )
}

export default PlanCard;
