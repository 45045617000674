import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Services from "../../components/Services/Services";

import "./Homepage.css";
import { Button } from "@mui/material";
import SvcPlatform from "../../components/SvcPlatform/SvcPlatform";
import Features from "../../components/Features/Features";
import Feedback from "../../components/Feedback/Feedback";
import axios from "axios";
import Swal from "sweetalert2";
import ReactGA from 'react-ga';

function Homepage() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [service, setService] = useState("");
    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};
        tempErrors.name = name ? "" : "Name is required.";
        tempErrors.phone = phone ? "" : "Phone number is required.";
        tempErrors.email = email ? "" : "Email is required.";

        const nameRegex = /^[A-Za-z\s]+$/;
        if (name && !nameRegex.test(name)) {
            tempErrors.name = "Name should not contain numbers.";
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (phone && !phoneRegex.test(phone)) {
            tempErrors.phone = "Phone number is invalid.";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailRegex.test(email)) {
            tempErrors.email = "Email is invalid.";
        }

        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const handleSubmit = (e) => {
        ReactGA.event({category:'formSubmit', action:'submit', label:'RequestCallback'});
        e.preventDefault();

        const data = {
            Name: name,
            PhoneNumber: phone,
            Email: email,
            ServiceDescription: service,
        };

        if (validate()) {
            axios
                .post(
                    "https://sheet.best/api/sheets/c179f9be-acc2-414c-b609-e38bf310820e",
                    data
                )
                .then((response) => {
                    console.log(response);
                    setName("");
                    setPhone("");
                    setEmail("");
                    setService("");

                    Swal.fire({
                        title: "Thank you!",
                        text: "Your request has been submitted successfully!",
                        icon: "success",
                    });
                })
                .catch((error) => {
                    console.error("Error submitting form:", error);
                    Swal.fire({
                        title: "Oops...",
                        text: "Something went wrong! Please try again later.",
                        icon: "error",
                        customClass: {
                            container: "mobile-swal-container", // Custom class for mobile responsiveness
                        },
                    });
                });
        } else {
            Swal.fire({
                title: "Form Errors",
                text: "One or more values are incorrect. Please check and try again.",
                icon: "error",
                customClass: {
                    container: "mobile-swal-container", // Custom class for mobile responsiveness
                },
            });
        }
    };
    return (
        <>
            <div className="container1">
                <h2 className="tag">
                    Let our experts manage your business compliances
                </h2>
                <h5 className="textBelowTag">
                    Compliance4u empowers more than 10000+ customers, 200+
                    businesses and 25+ expert firms across India!
                </h5>
                <Box className="boxcont">
                    <div className="textFieldCont" style={{ padding: "2%" }}>
                        <TextField
                            required
                            label="Enter Name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            error={!!errors.name}
                            helperText={errors.name}
                        />
                    </div>
                    <div className="textFieldCont" style={{ padding: "2%" }}>
                        <TextField
                            required
                            label="Enter phone number"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            error={!!errors.phone}
                            helperText={errors.phone}
                        />
                    </div>
                    <div className="textFieldCont" style={{ padding: "2%" }}>
                        <TextField
                            required
                            label="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </div>
                    <div className="textFieldCont" style={{ padding: "2%" }}>
                        <TextField
                            label="Service Description"
                            onChange={(e) => setService(e.target.value)}
                            value={service}
                        />
                    </div>

                    <div className="btnCont">
                        <Button
                            className="submitBtn"
                            variant="contained"
                            onClick={handleSubmit}>
                            Request callback
                        </Button>
                    </div>
                </Box>
            </div>
            <SvcPlatform />

            <div className="svcContMain">
                <Services />
            </div>
            <Features />
            <Feedback />
        </>
    );
}

export default Homepage;
