import React from 'react';
import Logo from "../../assets/logo.svg"
import './Footer.css'

function Footer() {
  return (
    <>
    <footer className="footer-section">
        <div className="container">
            
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo" style={{color:"white"}}>
                                <a href="index.html"><img src={Logo} className="img-fluid" alt="logo"/></a>
                                
                            </div>
                            <div className="footer-text">
                                <p>Compliance4u empowers more than 1000+ customers, 200+ businesses and 200+ expert firms across India!
                                </p>
                            </div>
                            <div className="footer-social-icon">
                                <span>Follow us</span>
                                <a href="https://www.youtube.com/@compliance4u" target='_blank' rel="noreferrer"><i className="fab fa-youtube" style={{color:"white", background:"#c70000"}}></i></a>
                                {/* <a href="/"><i className="fab fa-twitter twitter-bg"></i></a> */}
                                <a href="mailto:compliance4u.in@gmail.com"  target='_blank' rel="noreferrer"><i className="fab fa-google-plus-g google-bg"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/">about</a></li>
                                <li><a href="/">services</a></li>
                                <li><a href="/">portfolio</a></li>
                                <li><a href="/">Contact</a></li>
                                <li><a href="/">About us</a></li>
                                <li><a href="/">Our Services</a></li>
                                <li><a href="/">Expert Team</a></li>
                                <li><a href="/">Contact us</a></li>
                                <li><a href="/">Latest News</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Subscribe</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                            </div>
                            <div className="subscribe-form">
                                <form action="#">
                                    <input type="text" placeholder="Email Address"/>
                                    <button><i className="fab fa-telegram-plane"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="text-center text-lg-left">
                        <div className="copyright-text">
                            <p>Copyright &copy; {(new Date().getFullYear())} , All Right Reserved </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  );
}

export default Footer
