import React from 'react'
import "./Reasons.css"
function Reasons({src,title}) {
  return (
    
    <>
    {/* <div className='row'> */}
    <div className="col-md-4">
    <div className="vs-reasons-group reasons">
    <div className="vs-reasons-img"  style={{background:"none"}}>
    <img width="32" height="36" className="align-self-top mr-3" src={src} alt="Legal"/>
    </div>
    <div className="reasons-body">
      <h5 className='reasons-para' style={{marginTop:"4%"}}>
    <b>{title}</b></h5></div></div>
 
   </div>
   {/* </div> */}
    </>
  );

}

export default Reasons